<template>
  <!-- <gif-player
    :autoplay="true"
        src="https://cdn.theguardian.tv/webM/2015/07/20/150716YesMen_synd_768k_vp8.webm"
    ></gif-player> -->
  <v-img :src="imageSrc" width="1000px">
  </v-img>
</template>

<script>
import { ref } from '@vue/composition-api'

export default {
  components: {},
  created() {
    this.imageSrc = sessionStorage.getItem('imagePath')
    sessionStorage.removeItem('imagePath')
  },
  methods: {},
  setup() {
    const imageSrc = ref()

    return {
      imageSrc,
    }
  },
}
</script>
